import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import '../public/assets/css/vendors/bootstrap.css'
import '../public/assets/css/animate.min.css'
import '../public/assets/css/vendors/ion.rangeSlider.min.css'
import '../public/assets/css/vendors/slick/slick.css'
import '../public/assets/css/style.css'
import '../public/assets/css/vendors/slick/slick-theme.css'
import '../public/assets/js/bootstrap/bootstrap.bundle.min.js'
import '../public/assets/js/bootstrap/popper.min.js'
import '../public/assets/js/feather/feather.min.js'
import '../public/assets/js/clipboard.min.js'
import '../public/assets/js/lazysizes.min.js'
import '../public/assets/css/vendors/feather-icon.css'
import "bootstrap-icons/font/bootstrap-icons.css";

createApp(App).use(store).use(router).mount("#app");
