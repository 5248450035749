// store/profil.js
import axios from "axios";

export default {
  namespaced: true,
  state: {
    profilData: [],
  },
  getters: {
    getProfilData: (state) => state.profilData,
  },
  actions: {
    async postDataProfil({ commit }, profil) {
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/user/update-data-profil',
          profil,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          }
        );
        commit("SET_PROFIL", response.data);
      } catch (error) {
         console.log(error);
      }
    },
  },
  mutations: {
    SET_PROFIL(state, profilData) {
      state.profilData = profilData;
    },
  },
};
