import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  namespaced: true,
  state: {
    produkAll: [],
    produk_terbaru: [],
    produkPenjual:[],
    detail_produk:[],
  },
  getters: {
    GetProdukTerbaru: (state) => state.produk_terbaru,
    GetProdukAll: (state) => state.produkAll,
  },
  actions: {
    async fetchProdukTerbaru({ commit }) {
      try {
        const DataProduk = await axios.get(
          "https://dev.dspdigital.co.id/api/produk"
        );
        commit("Set_produk_terbaru", DataProduk.data.produk_terbaru);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchProdukAll({ commit }) {
      try {
        const DataProduk = await axios.get(
          "https://dev.dspdigital.co.id/api/produk"
        );
        commit("Set_produk_all", DataProduk.data.produk_all);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchProdukPenjual({ commit }) {
      try {
        var token = localStorage.getItem('token');
        const response = await axios.get('https://dev.dspdigital.co.id/api/penjual/produk', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        commit("SET_PENJUAL_PRODUK", response.data.produk);
      } catch (error) {
        console.log(error);
      }
    },


    async postProduk({ dispatch }, produk) { 
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/penjual/add/produk',
          produk,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          }
        );
        if(response.status == 200){
          toast.success('Berhasil Menambah Produk!', {
            autoClose: 500,
          });
        }
      } catch (error) {
          console.log(error)
      }
      finally{
        dispatch('fetchProdukPenjual'); // Perbarui total keranjang
      }
    },
    async deleteProduk({ dispatch }, produk) { 
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/penjual/produk/delete',
          {id_produk:produk},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            }
          }
        );
        if(response.status == 200){
          toast.success('Berhasil Menghapus Produk!', {
            autoClose: 500,
          });
        }
      } catch (error) {
        console.error(error)
      }finally{
        dispatch('fetchProdukPenjual'); // Perbarui total keranjang
      }
    },
    
    async fetchProdukDetailPenjual({ commit } , slug) {
      try {
        let token = localStorage.getItem('token');
        const DataProduk = await axios.get(
          `https://dev.dspdigital.co.id/api/penjual/detail/produk/${slug}`, 
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          });
        commit("DETAIL_PRODUK", DataProduk.data.produk);
      } catch (error) {
        console.log(error);
      }
    },

    async UpdateProduk({ dispatch }, produk) { 
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/penjual/produk/update',
           produk,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            }
          }
        );
        if(response.status == 200){
          toast.success('Berhasil Mengubah Produk!', {
            autoClose: 500,
          });
        }
      } catch (error) {
        console.error(error)
      }finally{
        dispatch('fetchProdukPenjual'); // Perbarui total keranjang
      }
    },
    
  },
  mutations: {
    Set_produk_terbaru(state, produk_terbaru) {
      state.produk_terbaru = produk_terbaru;
    },
    Set_produk_all(state, produkAll) {
      state.produkAll = produkAll;
    },
    SET_PENJUAL_PRODUK(state, produkPenjual) {
      state.produkPenjual = produkPenjual;
    },
    DETAIL_PRODUK(state, detail_produk) {
      state.detail_produk = detail_produk;
    },
  },
};
