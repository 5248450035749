import { createRouter, createWebHistory  } from "vue-router";

function isAuth(){
    const token = localStorage.getItem('token');
    if(token == null){
       document.location.href = "/login";
    }
}
function isAuthAdmin(){
  const token = localStorage.getItem('token');
  if(token == null){
     const roles = localStorage.getItem('roles');
     if(roles == 'admin'){
      document.location.href = "/admin/dashboard";
     }else{
      document.location.href = "/login/admin";
     }
 }
}

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("../views/Chat.vue"),
    beforeEnter: isAuth,
  },
  {
    path: "/checkout/",
    name: "checkout",
    component: () => import("../views/Checkout.vue"),
    beforeEnter: isAuth,
  },
  {
    path: "/daftar-penjual",
    name: "daftar-penjual",
    component: () => import("../views/DaftarPenjual.vue"),
  },
  {
    path: "/daftar",
    name: "daftar",
    component: () => import("../views/Daftar.vue"),
  },
  {
    path: "/dashboard-penjual",
    name: "dashboard-penjual",
    component: () => import("../views/DashboardPenjual.vue"),
    beforeEnter: isAuth,
  },
  {
    path: "/detail-produk/:slug",
    name: "detail-produk",
    component: () => import("../views/DetailProduk.vue"),
    props : true,
  },
  {
    path: "/keranjang",
    name: "keranjang",
    component: () => import("../views/Keranjang.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/produk-filter",
    name: "produk-filter",
    component: () => import("../views/ProdukFilter.vue"),
  },
  {
    path: "/profile-dashboard",
    name: "profile-dashboard",
    component: () => import("../views/ProfileDashboard.vue"),
    beforeEnter: isAuth,
  },
  {
    path: "/wishlist",
    name: "wishlist",
    component: () => import("../views/Wishlist.vue"),
  },
  // admin
  {
    path: "/login/admin",
    name: "loginAdmin",
    component: () => import("../views/admin/Login.vue"),
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: () => import("../views/admin/Index"),
    beforeEnter:isAuthAdmin,
  },
  {
    path: "/admin/kategori",
    name: "AdminKategori",
    component: () => import("../views/admin/kategori/Index"),
  },
  {
    path: "/admin/kategori/create",
    name: "AdminKategoriCreate",
    component: () => import("../views/admin/kategori/Create"),
  },
  {
    path: "/admin/kategori/edit/:id",
    name: "AdminKategoriedit",
    component: () => import("../views/admin/kategori/Edit"),
    props:true,
  },
  {
    path: "/admin/banner",
    name: "Adminbanner",
    component: () => import("../views/admin/banner/Index"),
  },
  {
    path: "/admin/banner/create",
    name: "AdminbannerCreate",
    component: () => import("../views/admin/banner/Create"),
  },
  {
    path: "/admin/banner/edit/:id",
    name: "AdminbannerEdit",
    component: () => import("../views/admin/banner/Edit"),
    props: true
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
