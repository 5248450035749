import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  namespaced: true,
  state: {
    banner: [],
    detail_banner: [],
  },
  getters: {
    Getbanner: (state) => state.banner,
    GetDetailBanner: (state) => state.detail_banner,
  },
  actions: {
    async FectBanner({ commit }) {
      try {
        const response = await axios.get(
          "https://dev.dspdigital.co.id/api/banner"
        );
        commit("SET_BANNER", response.data.data);
      } catch (error) {
         console.log(error);
      }
    },
    async postBanner({ commit }, banner) {
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/admin/banner/add',
          banner,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          }
        );
        if(response.status == 200){
          toast.success('Berhasil Menambah Banner!', {
            autoClose: 500,
          });
        }
      } catch (error) {
         console.log(error);
      } finally{
        window.location = '/admin/banner'
      }
    },
    async Fectdetail({ commit }, id) {
      try {
        const token = localStorage.getItem('token');
        const detail = await axios.get(
          `https://dev.dspdigital.co.id/api/admin/banner/show/${id}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }
        );
        commit("SET_DETAIL", detail.data.banner);
      } catch (error) {
         console.log(error);
      }
    },
    async editBanner({ commit , getters}, banner ) {
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          `https://dev.dspdigital.co.id/api/admin/banner/update/${getters.GetDetailBanner.id}`,
          banner,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          }
        );
        if(response.status == 200){
          toast.success('Berhasil Mengubah Banner!', {
            autoClose: 500,
          });
        }
      } catch (error) {
         console.log(error);
      } 
      finally{
        window.location = '/admin/banner'
      }
    },
    async deleteKategori({ dispatch }, id) {
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          `https://dev.dspdigital.co.id/api/admin/banner/delete/${id}`,
          {},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }
        );
        if(response.status == 200){
          toast.success('Berhasil Menghapus Banner!', {
            autoClose: 500,
          });
        }
      } catch (error) {
         console.log(error);
      } finally{
        dispatch('FectBanner');
      }
    },
  },
  mutations: {
    SET_BANNER(state, banner) {
      state.banner = banner;
    },
    SET_DETAIL(state, detail_banner) {
      state.detail_banner = detail_banner;
    },
  },
};
