import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  namespaced: true,
  state: {
    keranjang: [],
    error: [],
    isLoading: false,
  },
  getters: {
    getKeranjang: (state) => state.keranjang,
  },
  actions: {
    async fetchKeranjang({ commit }) {
      try {
        commit("setLoading", true);
        const token = localStorage.getItem('token');
        const response = await axios.get('https://dev.dspdigital.co.id/api/keranjang', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        commit("setKeranjang", response.data.keranjang);
      } catch (error) {
         console.error(error.response.data);
         commit("setEror", error.response.data.error);
      }finally {
        commit("setLoading", false); // Akhiri loading
     }
    },
    async AddToCarts({ dispatch }, keranjang) {
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/add/keranjang',
          keranjang,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        let data = response.data.Meta;
        if (data.code == 200) {
          toast.success('Berhasil Memasukan Ke Keranjang!', {
            autoClose: 500,
          });
        }
      } catch (error) {
        let dataSebelumLogin = error.response.data.error;
        if (dataSebelumLogin == 'Unauthorized') {
          toast.warning('Login Terlebih Dahulu', {
            autoClose: 500,
          });
        }
        if (error.response.data.Meta) {
          if (error.response.data.Meta.code == 400) {
            toast.warning('Produk Sudah Dalam Keranjang', {
              autoClose: 500,
            });
          } else {
            toast.error('Gagal Menambah Data Ke Keranjang', {
              autoClose: 500,
            });
          }
        }
      } finally {
        dispatch('fetchKeranjang'); // Perbarui total keranjang
      }
    },



    async qtyCart({ dispatch },keranjang) {
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/keranjang/qty',
          keranjang,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        console.log(response)
      } catch (error) {
         console.log(error)
      }
    }

    
    
  },
  mutations: {
    setKeranjang(state, keranjang) {
      state.keranjang = keranjang;
    },
    setEror(state, error) {
      state.error = error;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading; // Set status loading
    },
  },
};
