import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  namespaced: true,
  state: {
    checkoutGetData: [],
    hargaAll: 0
  },
  getters: {
    Getcheckouts: (state) => state.checkoutGetData,
  },
    actions: {
        async GetCheckout({ commit }, checkout) { 
            try {
              let token = localStorage.getItem('token');
              const response = await axios.post(
                'https://dev.dspdigital.co.id/api/checkout',
                checkout,
                {
                  headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                  }
                }
              );
             commit("SET_CHECKOUT", response.data.keranjang);
             commit('SET_HARGA', response.data.total_harga)
            } catch (error) {
                console.log(error)
            }
          },
    },
  mutations: {
    SET_CHECKOUT(state, checkoutGetData) {
        state.checkoutGetData = checkoutGetData;
      },
      SET_HARGA(state, hargaAll){
        state.hargaAll = hargaAll;
      }
  },
};
