import { createStore } from "vuex";
import kategori from "./modules/kategori";
import produk from "./modules/produk";
import keranjang from "./modules/keranjang";
import wishlist from "./modules/wishlist";
import checkout from "./modules/checkout";
import auth from "./modules/auth";
import profil from "./modules/profil";
import banner from "./modules/banner";
// import createPersistedState from "vuex-persistedstate";
export default createStore({
  modules: {
    kategori,
    produk,
     keranjang,
     wishlist,
     checkout,
     auth,
     profil,
     banner,
  },
  // plugins: [createPersistedState()],
});
