import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  namespaced: true,
  state: {
    kategori: [],
    detail:[]
  },
  getters: {
    GetKategori: (state) => state.kategori,
  },
  actions: {
    async FectKategori({ commit }) {
      try {
        const DataKategori = await axios.get(
          "https://dev.dspdigital.co.id/api/kategori"
        );
        commit("Set_Kategori", DataKategori.data.kategori);
        // console.log(DataKategori.data.kategori)
      } catch (error) {
         console.log(error);
      }
    },
    async Fectdetail({ commit }, id) {
      try {
        const token = localStorage.getItem('token');
        const detail = await axios.get(
          `https://dev.dspdigital.co.id/api/admin/kategori/show/${id}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }
        );
        commit("detail_kategori", detail.data.kategori);
      } catch (error) {
         console.log(error);
      }
    },
    async postKategori({ commit }, kategori) {
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/admin/kategori/post',
          kategori,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          }
        );
        if(response.status == 200){
          toast.success('Berhasil Menambah Kategori!', {
            autoClose: 500,
          });
        }
      } catch (error) {
         console.log(error);
      } finally{
        window.location = '/admin/kategori'
      }
    },
    async deleteKategori({ dispatch }, kategori) {
      try {
        let token = localStorage.getItem('token');
        console.log(kategori)
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/admin/kategori/delete',
          {id:kategori},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }
        );
        if(response.status == 200){
          toast.success('Berhasil Menghapus Kategori!', {
            autoClose: 500,
          });
        }
      } catch (error) {
         console.log(error);
      } finally{
        dispatch('FectKategori');
      }
    },
    async editKategori({ commit }, kategori) {
      try {
        console.log(kategori)
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/admin/kategori/update',
          kategori,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          }
        );
        if(response.status == 200){
          toast.success('Berhasil Mengubah Kategori!', {
            autoClose: 500,
          });
        }
      } catch (error) {
         console.log(error);
      } 
      finally{
        window.location = '/admin/kategori'
      }
    },
  },
  mutations: {
    Set_Kategori(state, kategori) {
      state.kategori = kategori;
    },
    detail_kategori(state,detail){
      state.detail = detail;
    }
  },
};
