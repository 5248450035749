import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  namespaced: true,
  state: {
    wishlist: [],
    
  },
  getters: {
    GetWishlist: (state) => state.wishlist,
  },
  actions: {
    async FetchWishlist({ commit }) {
        try {
            commit("setLoading", true);
            const token = localStorage.getItem('token');
            const response = await axios.get('https://dev.dspdigital.co.id/api/wishlist', {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
            commit("SET_WISHLIST", response.data.wishlist);
          } catch (error) {
             console.error(error.response.data);
          }
    },
    async AddToWishlist({ dispatch }, wishlist) { 
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/wishlist/add',
          wishlist,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        if(response.data.Meta.code == 200){
            toast.success('Berhasil Menambah Ke Wishlist', {
                autoClose: 500,
          });
        }
        if(response.data.Meta.code == 400){
            toast.warning('Produk Sudah Dalam Wishlist', {
                autoClose: 500,
              });
        }
      } catch (error) {
        if(error.response.data.error == 'Unauthorized'){
            toast.warning('Login Terlebih Dahulu', {
                autoClose: 500,
              });
        }
      }
     
    },
    async AddDeleteWishlist({ dispatch }, wishlist) { 
        try {
          let token = localStorage.getItem('token');
          const response = await axios.post(
            'https://dev.dspdigital.co.id/api/wishlist/delete',
            wishlist,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            }
          );
          if(response.data.meta.code == 200){
              toast.success('Berhasil Menghapus Data Wishlist', {
                  autoClose: 500,
            });
          }
        } catch (error) {
             
        }finally{
            dispatch('FetchWishlist'); 
        }
      }
  },
  mutations: {
    SET_WISHLIST(state, wishlist) {
      state.wishlist = wishlist;
    },
  },
};
