import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  namespaced: true,
  state: {
    auth: [],
    penjual: [],
  },
  getters: {
    Getauth: (state) => state.auth,
  },
  actions: {
    async FectMe({ commit }) {
      try {
        let token = localStorage.getItem('token');
        const response = await axios.get('https://dev.dspdigital.co.id/api/me', {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
        commit("SET_ME", response.data);
      } catch (error) {
         console.log(error);
      }
    },
    async postPotoProfil({ dispatch }, profil) {
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/user/update-poto-profil',
          profil,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          }
        );
      } catch (error) {
         console.log(error);
      }finally{
        dispatch('FectMe'); 
      }
    },
    async postDataProfil({ dispatch }, profil) {
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/user/update-data-profil',
          profil,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          }
        );
        // if(response.data)
        if(response.data.meta.code == 200){
          toast.success('Berhasil Memasukan Ke Keranjang!', {
            autoClose: 500,
          });
        }
        commit("SET_PROFIL", response.data);
      } catch (error) {
         console.log(error);
      }finally{
        dispatch('FectMe'); 
      }
    },

    async FectMePenjual({ commit }) {
      try {
        let token = localStorage.getItem('token');
        const response = await axios.get('https://dev.dspdigital.co.id/api/penjual/me', {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
        commit("SET_ME_PENJUAL", response.data.penjual);
      } catch (error) {
         console.log(error);
      }
    },

    async postLogoToko({ dispatch }, logo) {
      try {
        let token = localStorage.getItem('token');
        const response = await axios.post(
          'https://dev.dspdigital.co.id/api/penjual/toko/logo',
          logo,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          }
        );
      } catch (error) {
         console.log(error);
      }finally{
        dispatch('FectMePenjual'); 
      }
    },

    

  },
  mutations: {
    SET_ME(state, auth) {
      state.auth = auth;
    },
    SET_ME_PENJUAL(state, penjual) {
      state.penjual = penjual;
    },
  },
};
